import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { Img } from '@wix/communities-image-lib';
import useOnScreen from '../../hooks/use-on-screen';
import { getMobileViewportHeight } from '../../services/viewport';
import {
  isEditor as getIsEditor,
  isSSR as getIsSSR,
} from '../../store/basic-params/basic-params-selectors';
import { WithResponsiveContext } from '../responsive-listener/responsive-context';
import withResponsiveContext from '../responsive-listener/with-responsive-context';
import { connect } from '../runtime-context';
import { getImageDimensions } from './get-image-dimensions';
import styles from './post-list-item-image.scss';

type OwnProps = {
  image: string;
  url?: string;
  isPublic?: boolean;
  width: number;
  height: number;
  layoutName: string;
};

type Props = OwnProps &
  WithResponsiveContext &
  ConnectedProps<typeof connector>;

export const PostListItemImageMobile: React.FC<Props> = ({
  image,
  url,
  isPublic,
  width,
  height,
  layoutName,
  rootWidth,
  isSSR,
  isEditor,
}) => {
  const { isOnScreen, ref } = useOnScreen(getMobileViewportHeight(), isEditor);

  const dimensions = getImageDimensions({
    container: { width, height },
    component: { width: rootWidth },
    layoutName,
    isSSR,
  });

  return (
    <div className={classNames(styles.container, styles.mobile)}>
      <Img
        src={image}
        className={styles.image}
        ref={ref}
        isOnScreen={isOnScreen}
        alt=""
        encode={true}
        {...dimensions}
        {...(isPublic ? { 'data-pin-url': url } : { 'data-pin-nopin': true })}
      />
    </div>
  );
};

const connector = connect((state) => ({
  isSSR: getIsSSR(state),
  isEditor: getIsEditor(state),
}));

export default flowRight(
  connector,
  withResponsiveContext,
)(PostListItemImageMobile);
