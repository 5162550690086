import React from 'react';
import classNames from 'classnames';
import { flowRight } from 'lodash';
import { ConnectedProps } from 'react-redux';
import { POST_LIST_COMPONENT_MASONRY } from '@wix/communities-blog-client-common';
import withComponents, {
  WithComponents,
} from '../../../common/components/components-provider/with-components';
import LoadMore from '../../../common/components/load-more';
import Loader from '../../../common/components/loader';
import { connect } from '../../../common/components/runtime-context';
import withDeviceType, {
  WithDeviceType,
} from '../../../common/hoc/with-device-type';
import withFeedMetadataSettings, {
  WithFeedMetadataSettings,
} from '../../../common/hoc/with-feed-metadata-settings';
import withLayoutProps, {
  WithLayoutProps,
} from '../../../common/hoc/with-layout-props';
import useOnScreen from '../../../common/hooks/use-on-screen';
import { getIsPostListFullWidthEnabled } from '../../../common/selectors/app-settings-selectors';
import { getIsLazyPaginationEnabled } from '../../../common/selectors/pagination-selectors';
import { getLayoutConfig } from '../../../common/services/layout-config';
import { getPostListCssVars } from '../../../common/services/post-list-css-vars';
import { isEditor as getIsEditor } from '../../../common/store/basic-params/basic-params-selectors';
import { NormalizedPost } from '../../../common/types';
import MasonryWrapper from '../masonry-wrapper';
import RemainingPostLoader from './remaining-post-loader';
import styles from './post-list.scss';

type LayoutConfig = ReturnType<typeof getLayoutConfig>;

type OwnProps = {
  isLoading: boolean;
  allPosts: NormalizedPost[];
  entityCount: number;
  page: number;
  pageSize: number;
  loadRest: () => void;
  loadMore: () => void;
  pageStart: number;
  currentPagePosts: NormalizedPost[];
};

type Props = OwnProps &
  WithLayoutProps &
  WithComponents &
  WithFeedMetadataSettings &
  WithDeviceType &
  ConnectedProps<typeof connector>;

const PostList: React.FC<Props> = (props) => {
  const {
    layoutType,
    layoutName,
    isMetadataFooterVisible,
    isPostListFullWidthEnabled,
  } = props;
  const layoutConfig = getLayoutConfig(layoutType);
  const containerClassName = classNames(
    styles.container,
    isPostListFullWidthEnabled && styles.fullWidth,
    styles[layoutName],
    !isMetadataFooterVisible && styles.withoutFooter,
    'post-list',
  );

  return (
    <div className={containerClassName}>
      <MasonryWrapper
        isEnabled={
          layoutConfig.listComponentName === POST_LIST_COMPONENT_MASONRY
        }
      >
        <Layout {...props} layoutConfig={layoutConfig} />
      </MasonryWrapper>
    </div>
  );
};

const Layout: React.FC<Props & { layoutConfig: LayoutConfig }> = (props) => {
  const {
    isLoading,
    allPosts,
    entityCount,
    isLazyPaginationEnabled,
    page,
    pageSize,
    loadRest,
    loadMore,
    pageStart,
    currentPagePosts,
    isEditor,
  } = props;

  if (loadRest && isLazyPaginationEnabled) {
    const totalRemainingPostCount =
      entityCount - (page - 1) * pageSize - currentPagePosts.length;
    const hasRemainingPosts =
      currentPagePosts.length < pageSize && totalRemainingPostCount > 0;

    return (
      <>
        <List {...props} showLoader />

        {currentPagePosts.length > 0 && hasRemainingPosts && (
          <RemainingPostLoaderOnScreen
            isEditor={isEditor}
            loadRemainingPosts={loadRest}
          />
        )}
      </>
    );
  }

  if (loadMore) {
    return (
      <LoadMore
        loadMore={loadMore}
        loader={<Loader />}
        isLoading={isLoading}
        hasMore={allPosts.length < entityCount}
        pageStart={pageStart}
      >
        <List {...props} />
      </LoadMore>
    );
  }

  return <List {...props} showLoader />;
};

const List: React.FC<
  Props & {
    layoutConfig: LayoutConfig;
    showLoader?: boolean;
  }
> = ({
  allPosts,
  entityCount,
  layoutName,
  isLoading,
  layoutConfig,
  showLoader = false,
  ...props
}) => {
  const ListComponent = (props as any)[layoutConfig.listComponentName];
  const ItemComponent = (props as any)[layoutConfig.itemComponentName];
  return (
    <div style={getPostListCssVars() as any}>
      <ListComponent
        key="posts"
        posts={allPosts}
        entityCount={entityCount}
        type={layoutName}
        ItemComponent={ItemComponent}
        itemConfig={layoutConfig.itemConfig}
        {...props}
      />
      {showLoader && isLoading && (
        <Loader dataHook="post-list__loader" key="loader" />
      )}
    </div>
  );
};

type ScreenProps = {
  loadRemainingPosts: () => void;
  isEditor: boolean;
};

const RemainingPostLoaderOnScreen: React.FC<ScreenProps> = ({
  loadRemainingPosts,
  isEditor,
}) => {
  const { isOnScreen, ref } = useOnScreen(undefined, isEditor);

  if (!isOnScreen) {
    return <div ref={ref as any} />;
  }

  return <RemainingPostLoader loadRemainingPosts={loadRemainingPosts} />;
};

const connector = connect((state, _, actions) => ({
  isPostListFullWidthEnabled: getIsPostListFullWidthEnabled(state),
  isLazyPaginationEnabled: getIsLazyPaginationEnabled(state),
  onLikeClick: actions.incrementPostLikeCount,
  isEditor: getIsEditor(state),
}));

export default flowRight(
  connector,
  withComponents,
  withDeviceType,
  withFeedMetadataSettings,
  withLayoutProps(),
)(PostList);
